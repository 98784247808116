import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from "./services/auth/auth.service";
import { ApiService } from "./services/api.service";
import { JwtService } from "./services/auth/jwt.service";
import { PushNotificationService } from "./services/notification/push-notification.service";
import { ThemeService } from "./services/theme.service";
import { LocationService } from "./services/locations/location.service";
import { DepartmentService } from "./services/lesson/department.service";
import { GradeService } from "./services/lesson/grade.service";
import { SharedDataService } from "./services/shared-data.service";
import { UserService } from "./services/user/user.service";
import { UserProfileService } from "./services/user/user-profile.service";
import { ScrollService } from "./services/scroll.service";
import { PlaylistService } from "./services/video/playlist.service";
import { VideoService } from "./services/video/video.service";
import { VideoCommentService } from "./services/video/video-comment.service";
import { ExamService } from "./services/exam/exam.service";
import { ExamScoreService } from "./services/exam/exam-score.service";
import { PublisherService } from "./services/exam/publisher.service";
import { FileService } from "./services/file.service";
import { PlanService } from "./services/plans/plan.service";
import { DocumentService } from "./services/document/document.service";
import { LessonService } from "./services/lesson/lesson.service";
import { UserWorkbookService } from "./services/workbook/user-workbook.service";
import { NotificationSettingService } from "./services/notification/notification-setting.service";
import { ApplicationSettingService } from "./services/application/application-setting.service";
import { SocketIoModule } from "ngx-socket-io";
import { LiveStreamWsService } from "./services/live-stream/live-stream-ws.service";
import { LiveStreamService } from "./services/live-stream/live-stream.service";
import { PollService } from "./services/poll/poll.service";
import { ExamMentorService } from "./services/exam/exam-mentor.service";
import { BlogService } from "./services/blog/blog.service";
import { NotificationService } from "./services/notification/notification.service";
import { PaymentService } from "./services/payment/payment.service";
import { AppAbilityService } from "./services/auth/app-ability.service";
import { SearchService } from "./services/application/search.service";
import { ReminderService } from "./services/reminder/reminder.service";
import { StorageService } from "./services/storage.service";
import { SchoolService } from "./services/user/school.service";
import { CampaignService } from "./services/campaign/campaign.service";
import { WorkReportService } from './services/plans/work-report/work-report.service';
import { PriceService } from "./services/payment/price.service";
import { FacebookPixelService } from "./services/tracker/facebook-pixel.service";
import { ScriptsService } from "./services/tracker/scripts.service";
import { WorkbookService } from "./services/workbook/workbook.service";
import { GoogleTagManagerService } from "./services/tracker/google-tag-manager.service";
import { TrackerService } from "./services/tracker/tracker.service";
import { SurveyService } from "./services/survey/survey.service";
import { CommunityService } from "./services/community/community.service";
import { CommunityCommentService } from "./services/community/community-comment.service";


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SocketIoModule,
  ],
  providers: [
    ApiService,
    AuthService,
    JwtService,
    PushNotificationService,
    ThemeService,
    LocationService,
    DepartmentService,
    GradeService,
    SharedDataService,
    UserService,
    UserProfileService,
    ScrollService,
    PlaylistService,
    VideoService,
    VideoCommentService,
    ExamService,
    ExamScoreService,
    PublisherService,
    FileService,
    PlanService,
    DocumentService,
    LessonService,
    UserWorkbookService,
    NotificationSettingService,
    ApplicationSettingService,
    LiveStreamWsService,
    LiveStreamService,
    PollService,
    ExamMentorService,
    BlogService,
    CommunityService,
    CommunityCommentService,
    NotificationService,
    PaymentService,
    AppAbilityService,
    SearchService,
    ReminderService,
    FacebookPixelService,
    GoogleTagManagerService,
    TrackerService,
    StorageService,
    SchoolService,
    CampaignService,
    WorkReportService,
    PriceService,
    ScriptsService,
    WorkbookService,
    SurveyService,
  ]
})
export class CoreModule {
}
